import React from 'react';
import '../styles/Hero2.css';
import logoOutline from '../assets/Doch_Logo_outline.svg';
import bg2 from '../assets/bg2.png'
import bgOLD from '../assets/bgOLD.jpg'
import { Link } from 'react-scroll';


const Hero2_1 = () => {
    return (
        <section className="hero2_1" id="" role="img" aria-labelledby="hero2_1-description">
            <p id="hero2_1-description" hidden>
                Dieses Bild zeigt eine Auswahl frischer Gemüsesorten, darunter Karotten, Lauch, Knoblauch, Pastinaken und Petersilie, 
                die in einem silbernen Drahtsieb angeordnet sind. Das Sieb schwebt vor einem schwarzen Hintergrund, 
                und darunter rieselt orangefarbenes Pulver – vermutlich ein Gewürz – heraus. Das Gemüse ist frisch und farbenfroh, 
                während das Pulver Dynamik und den Verarbeitungsprozess von frischen Zutaten zu einer Gewürzmischung symbolisiert.
            </p>
            {/* <div className='bg2'> */}
            {/* <div className='color'></div> */}
            <div className="container">
                {/* <div className='text'>
                    <h1>Handwerk & Natur</h1>
                    <h3 className='p1_1'>in perfekter Balance</h3>
                    <p>Würzen bedeutet für uns mehr als nur Geschmack – es bedeutet, den <strong>echten Charakter</strong> der Zutaten zu unterstreichen. Unsere Mischungen sind fein abgestimmt, bewusst salzarm und enthalten keine künstlichen Zusätze.</p>
                    <p>Statt intensiver Aromen oder Geschmacksverstärker setzen wir auf das <strong>perfekte Zusammenspiel natürlicher Zutaten</strong>. Die geheime Würzkombination bringt die individuelle Note jedes Gerichts hervor, ohne es zu überdecken – <strong>so wird gesunde Würzkraft aus der Natur erlebbar</strong>.</p>
                    <p>DOCH! ist mehr als nur eine Würze – es ist die <strong>Essenz puren Geschmacks</strong>.</p>
                </div> */}
                <div className="text">
                    {/* <h2 className="title"><img src={logoOutline} alt="DOCH!" /> würzt.</h2> */}
                    <h2>Natur & Handwerk</h2>
                    <h3>in perfekter Balance</h3>
                    <p>Unsere handverlesenen Würzmischungen basieren auf einer einzigartigen Rezeptur mit <strong>wenig Salz</strong>, die den natürlichen Geschmack der Zutaten betont – <strong>ganz ohne künstliche Zusatzstoffe, Geschmacksverstärker oder Aromen.</strong></p>
                    <p>DOCH! bietet sowohl fein abgestimmte Gemüse-Würzmischungen als auch reine Würzpulver – für noch mehr <strong>Vielfalt in der Küche</strong>. Von Karotten, Sellerie und Pastinaken bis hin zu aromatischen Kräutern wie Thymian und Oregano, harmonisch kombiniert für eine natürliche, ausgewogene Würze.</p>
                    <p>Oder ganz pur als fein vermahlenes Zwiebel- oder Knoblauchpulver: ideal für alle, die gezielt einzelne Aromen einsetzen möchten.</p>
                    {/* <p>Das Ergebnis? <strong>Eine feine, natürliche Würze, die dein Essen veredelt, statt es zu überdecken – für puren Genuss ohne Kompromisse</strong>.</p> */}
                    <p>Kurz gesagt: <strong>Mit DOCH! holst du dir gesunde Würzkraft aus der Natur in deine Küche</strong>.</p>
                </div>
            </div>

            {/* </div> */}

        </section>
    );
};

export default Hero2_1;
