import React from "react";
import '../styles/Footer.css'
import logoOutline from '../assets/Doch_Logo_outline.svg';
import logo2 from '../assets/logo2.png';
import { FaPhone, FaEnvelope } from 'react-icons/fa'; // Импорт иконок из react-icons



const Footer = () => {
    return (
        <footer>
            <div className="footerLeft">
                <div className="contactItem">
                    <a href="tel:+4322626246889" className="contactLink">+43 2262 6246889</a>
                    <FaPhone className="icon" />
                </div>
                <div className="contactItem">
                    <a href="mailto:doch@dochwuerzt.com" className="contactLink">doch@dochwuerzt.com</a>
                    <FaEnvelope className="icon" />
                </div>
            </div>

            <div className="footerMiddle">
                <img className="footerLogo" src={logoOutline} alt="logo" />
                <p>GESCHMACKS-MANUFAKTUR</p>
                {/* <p>&copy; {new Date().getFullYear()} Z-Works. </p> */}
            </div>

            <div className="footerRight">
                <a className="socials instagram" href="https://www.instagram.com/doch_wuerzt/" target="_blank">
                    <svg version="1.1" id="socials_instagram" fill="rgb(243, 149, 75)" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40">
                        <path d="M28.1,7H11.9C9.2,7,7,9.2,7,11.9v16.2c0,2.7,2.2,4.9,4.9,4.9h16.2c2.7,0,4.9-2.2,4.9-4.9V11.9 C33,9.2,30.8,7,28.1,7z M20,27.2c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2C27.2,24,24,27.2,20,27.2z M28.2,13.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C29.6,12.6,29,13.2,28.2,13.2z"/>
                        <path d="M20,14.8c-2.9,0-5.2,2.3-5.2,5.2s2.3,5.2,5.2,5.2c2.9,0,5.2-2.3,5.2-5.2S22.9,14.8,20,14.8z"/>
                    </svg>
                </a>
                <a className="socials facebook" href="https://www.facebook.com/DOCHwuerzt" target="_blank">
                    <svg version="1.1" id="socials_facebook" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40">
                        <path d="M24.6,8h-1.2h-1.3c-0.8,0-1.6,0.1-2.3,0.4c-0.7,0.3-1.4,0.8-1.9,1.4c-0.5,0.6-1,1.3-1.2,2c-0.3,0.8-0.3,1.6-0.3,2.3v1.7c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1h-0.5c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.8-0.5,1.2v1.7c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5H16c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3v8.7c0,0.3,0.1,0.7,0.4,0.9c0.2,0.2,0.6,0.4,0.9,0.4h3.2c0.4,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9V22c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.7c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.8,0.5-1.2v-1.7c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5h-0.7c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-1.3c0-1.4,0.4-1.4,1.3-1.4h1.1c0.5,0,0.9-0.2,1.3-0.5c0.3-0.3,0.5-0.8,0.5-1.3V9.8c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.4-0.6s-0.4-0.3-0.6-0.4C25.1,8,24.9,8,24.6,8L24.6,8z"/>
                    </svg>
                </a>
            </div>


        </footer>
    );
};

export default Footer;