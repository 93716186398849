import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from './Loader';
import ProductIcons from './ProductIcons';
import iconRecycle from '../assets/icons/icon_Recycle.svg';
import iconVegan from '../assets/icons/icon_Vegan.svg';
import icon100Naturlich from '../assets/icons/icon_100_Naturlich.svg';
import iconGentechnik from '../assets/icons/icon_Gentechnik.svg';
import iconGlutamat from '../assets/icons/icon_Glutamat.svg';
import iconZucker from '../assets/icons/icon_Zucker.svg';
import '../styles/Produkte.css';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [nutritions, setNutritions] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Функция для форматирования чисел
    const formatNumber = (value) => {
        const num = parseFloat(value);
        if (isNaN(num)) return value;
        if (num % 1 === 0) {
            return num;
        }
        const fixed = num.toFixed(1);
        return fixed.endsWith('.0') ? parseInt(fixed, 10) : fixed;
    };

    // Функция для форматирования даты в формате dd.mm.yyyy
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        if (isNaN(date)) return dateStr;
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const getImageUrl = (image) => {
        if (!image || !image.filename_disk) return null;
        const baseUrl = 'https://doch-admin.mobile-bühne.at/assets/';
        return `${baseUrl}${image.filename_disk}`;
    };

    const lightOrDark = (color) => {
        let r; let g; let b;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
      
          // If HEX --> store the red, green, blue values in separate variables
          color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      
          r = color[1];
          g = color[2];
          b = color[3];
        } else {
      
          // If RGB --> Convert it to HEX: http://gist.github.com/983661
          color = +("0x" + color.slice(1).replace( 
            color.length < 5 && /./g, '$&$&'
          )
                   );
      
          r = color >> 16;
          g = color >> 8 & 255;
          b = color & 255;
        }
      
        // HSP equation from http://alienryderflex.com/hsp.html
        const hsp = Math.sqrt(
          0.299 * (r * r) +
          0.587 * (g * g) +
          0.114 * (b * b)
        );
      
        // Using the HSP value, determine whether the color is light or dark
        if (hsp>127.5) {
          return 'light';
        } 
        else {
          return 'dark';
        }
    }

    const getColor = (fillColor) => {
        const color = lightOrDark(fillColor) === 'light' ? `color-mix(in srgb, ${fillColor}, #000 25%)` : fillColor;
        return color;
    }

    // Функция для рендера информации о питательных веществах
    const renderNutrition = (nutrition) => {
        if (!nutrition) return null;

        return (
            <div>
                {nutrition.Energie_kJ && nutrition.Energie_Kcal && (
                    <div className='nutrition'>
                        <p>Energie</p>
                        <p>
                            {formatNumber(nutrition.Energie_kJ)} kj / {formatNumber(nutrition.Energie_Kcal)} Kcal
                        </p>
                    </div>
                )}
                {nutrition.Fett && (
                    <div className='nutrition'>
                        <p>Fett</p>
                        <p>{formatNumber(nutrition.Fett)} g</p>
                    </div>
                )}
                {nutrition.Fettsaeuren && (
                    <div className='nutrition'>
                        <p>- davon gesättigte Fettsäuren</p>
                        <p>{formatNumber(nutrition.Fettsaeuren)} g</p>
                    </div>
                )}
                {nutrition.Kohlenhydrate && (
                    <div className='nutrition'>
                        <p>Kohlenhydrate</p>
                        <p>{formatNumber(nutrition.Kohlenhydrate)} g</p>
                    </div>
                )}
                {nutrition.Zuker && (
                    <div className='nutrition'>
                        <p>- davon Zucker</p>
                        <p>{formatNumber(nutrition.Zuker)} g</p>
                    </div>
                )}
                {nutrition.Ballaststoffe && (
                    <div className='nutrition'>
                        <p>Ballaststoffe</p>
                        <p>{formatNumber(nutrition.Ballaststoffe)} g</p>
                    </div>
                )}
                {nutrition.Eiweiss && (
                    <div className='nutrition'>
                        <p>Eiweiss</p>
                        <p>{formatNumber(nutrition.Eiweiss)} g</p>
                    </div>
                )}
                {nutrition.Salz && (
                    <div className='nutrition'>
                        <p>Salz</p>
                        <p>{formatNumber(nutrition.Salz)} g</p>
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        const productsEndpoint = 'https://doch-admin.mobile-bühne.at/items/Produkte?fields=*,Kategorie.id,Kategorie.Name,Bild.id,Bild.filename_disk';
        const nutritionsEndpoint = 'https://doch-admin.mobile-bühne.at/items/Naehrwerte?fields=*';

        Promise.all([
            axios.get(productsEndpoint),
            axios.get(nutritionsEndpoint)
        ])
            .then(([productsRes, nutritionsRes]) => {
                // Фильтруем только опубликованные продукты
                const publishedProducts = productsRes.data.data.filter(
                    product => product.status === 'published'
                );

                // Фильтруем nutritions - оставляем только published
                const publishedNutritions = nutritionsRes.data.data.filter(
                    nutrition => nutrition.status === 'published'
                );
                // Формируем mapping по id
                const nutritionsMapping = {};
                publishedNutritions.forEach(nutrition => {
                    nutritionsMapping[nutrition.id] = nutrition;
                });

                setProducts(publishedProducts);
                setNutritions(nutritionsMapping);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, []);

    // Используем Loader, если данные ещё загружаются
    if (loading) return <Loader />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
        <div className="products-container"><h1 className="title">Produkte</h1></div>
        <main className="products">
            

            {products.map(product => {
                const bgColor = (product.Kategorie?.id % 2 === 0 ) ? (
                    'var(--beige)'
                ) : (
                    'var(--gray)'
                );
                
                return (
                    <Link to={`/produkte/${product.id}`} key={product.id} className={`product-card ${lightOrDark(product.Farbe)}`}  style={{backgroundColor: `${product.Farbe}`}}>
                    {/* <Link to={`/produkte/${product.id}`} key={product.id} className={`product-card ${lightOrDark(product.Farbe)}`}  style={{backgroundColor: `color-mix(in srgb, ${product.Farbe} 100%, #fff 50%)`}}> */}
                        <div className="product-card-bg" style={{backgroundColor: `${bgColor}`}}></div>
                        <div className="product-card-img">
                            {product.Bild && product.Bild.filename_disk ? (
                                <img
                                    src={getImageUrl(product.Bild)}
                                    alt={product.Name}
                                    loading="lazy"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "/images/placeholder.png";
                                    }}
                                />
                            ) : (
                                <div className="product-img-placeholder">No Icon</div>
                            )}
                        </div>
                        <div className="product-card-content">
                            <h3>{product.Kategorie?.Name}</h3>
                            <h2>{product.Name}</h2>

                            {product.Beschreibung && (
                                <p>{product.Beschreibung}</p>
                            )}
                        </div>
                    </Link>
                );
            })}
        </main>
        </>
    );
};

export default Products;
