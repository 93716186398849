import React from 'react';
import '../styles/Loader.css'

const Loader = () => {
    const text = 'DOCH!'.split('').map((ch) => (ch === ' ' ? null : ch));

    const renderText = () =>
        text.map((char, index) =>
            char ? (
                <span key={index}>
                    <sup>{char}</sup>
                    <sub>{char}</sub>
                </span>
            ) : (
                <i key={index} />
            )
        );

    return (
        <>
            <section
                style={{ height: '100%', width: '100%', position: 'absolute', zIndex: '10000' }}
            >
                <div className="app-loader light">
                    <h1 className="app-loader__text">{renderText()}</h1>
                    <div className="app-loader__wisk">
                        <div className="app-loader__wisk-inner">
                            <div className="app-loader__wisk-handle" />
                            <div className="app-loader__wisk-wires" />
                        </div>
                    </div>
                    <div className="app-loader__bowl" />
                </div>
            </section>
        </>
    );
};

export default Loader;
