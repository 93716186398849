import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Loader from './Loader';
import ProductIcons from './ProductIcons';
import iconRecycle from '../assets/icons/icon_Recycle.svg';
import iconVegan from '../assets/icons/icon_Vegan.svg';
import icon100Naturlich from '../assets/icons/icon_100_Naturlich.svg';
import iconGentechnik from '../assets/icons/icon_Gentechnik.svg';
import iconGlutamat from '../assets/icons/icon_Glutamat.svg';
import iconZucker from '../assets/icons/icon_Zucker.svg';
import '../styles/Produkte.css';

const ProductDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState({});
    /* const [nutritions, setNutritions] = useState({}); */
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Функция для форматирования чисел
    const formatNumber = (value) => {
        const num = parseFloat(value);
        if (isNaN(num)) return value;
        if (num % 1 === 0) {
            return num;
        }
        const fixed = num.toFixed(1);
        return fixed.endsWith('.0') ? parseInt(fixed, 10) : fixed;
    };

    // Функция для форматирования даты в формате dd.mm.yyyy
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        if (isNaN(date)) return dateStr;
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const getImageUrl = (image) => {
        if (!image || !image.filename_disk) return null;
        const baseUrl = 'https://doch-admin.mobile-bühne.at/assets/';
        return `${baseUrl}${image.filename_disk}`;
    };

    const lightOrDark = (color) => {
        let r; let g; let b;

        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
      
          // If HEX --> store the red, green, blue values in separate variables
          color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      
          r = color[1];
          g = color[2];
          b = color[3];
        } else {
      
          // If RGB --> Convert it to HEX: http://gist.github.com/983661
          color = +("0x" + color.slice(1).replace( 
            color.length < 5 && /./g, '$&$&'
          )
                   );
      
          r = color >> 16;
          g = color >> 8 & 255;
          b = color & 255;
        }
      
        // HSP equation from http://alienryderflex.com/hsp.html
        const hsp = Math.sqrt(
          0.299 * (r * r) +
          0.587 * (g * g) +
          0.114 * (b * b)
        );
      
        // Using the HSP value, determine whether the color is light or dark
        if (hsp>127.5) {
          return 'light';
        } 
        else {
          return 'dark';
        }
    }

    const getColor = (fillColor) => {
        const color = lightOrDark(fillColor) === 'light' ? `color-mix(in srgb, ${fillColor}, #000 25%)` : fillColor;
        return color;
    }

    // Функция для рендера информации о питательных веществах
    const renderNutrition = (nutrition) => {
        if (!nutrition) return null;

        return (
            <div>
                {nutrition.Energie_kJ && nutrition.Energie_Kcal && (
                    <div className='nutrition'>
                        <p>Energie</p>
                        <p>
                            {formatNumber(nutrition.Energie_kJ)} kj / {formatNumber(nutrition.Energie_Kcal)} Kcal
                        </p>
                    </div>
                )}
                {nutrition.Fett && (
                    <div className='nutrition'>
                        <p>Fett</p>
                        <p>{formatNumber(nutrition.Fett)} g</p>
                    </div>
                )}
                {nutrition.Fettsaeuren && (
                    <div className='nutrition'>
                        <p>- davon gesättigte Fettsäuren</p>
                        <p>{formatNumber(nutrition.Fettsaeuren)} g</p>
                    </div>
                )}
                {nutrition.Kohlenhydrate && (
                    <div className='nutrition'>
                        <p>Kohlenhydrate</p>
                        <p>{formatNumber(nutrition.Kohlenhydrate)} g</p>
                    </div>
                )}
                {nutrition.Zuker && (
                    <div className='nutrition'>
                        <p>- davon Zucker</p>
                        <p>{formatNumber(nutrition.Zuker)} g</p>
                    </div>
                )}
                {nutrition.Ballaststoffe && (
                    <div className='nutrition'>
                        <p>Ballaststoffe</p>
                        <p>{formatNumber(nutrition.Ballaststoffe)} g</p>
                    </div>
                )}
                {nutrition.Eiweiss && (
                    <div className='nutrition'>
                        <p>Eiweiss</p>
                        <p>{formatNumber(nutrition.Eiweiss)} g</p>
                    </div>
                )}
                {nutrition.Salz && (
                    <div className='nutrition'>
                        <p>Salz</p>
                        <p>{formatNumber(nutrition.Salz)} g</p>
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        const productsEndpoint = `https://doch-admin.mobile-bühne.at/items/Produkte/${id}?fields=*,Kategorie.Name,Bild.id,Bild.filename_disk,Naehrwerte.*`;

        axios.get(productsEndpoint)
            .then(productRes => {
                console.log(productRes);
                const prod = productRes.data.data;
                /* // Фильтруем только опубликованные продукты
                const publishedProducts = productsRes.data.data.filter(
                    product => product.status === 'published'
                );

                // Фильтруем nutritions - оставляем только published
                const publishedNutritions = nutritionsRes.data.data.filter(
                    nutrition => nutrition.status === 'published'
                );
                // Формируем mapping по id
                const nutritionsMapping = {};
                publishedNutritions.forEach(nutrition => {
                    nutritionsMapping[nutrition.id] = nutrition;
                }); */

                setProduct( prod );
                /* setNutritions(nutritionsMapping); */
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, []);

    // Используем Loader, если данные ещё загружаются
    if (loading) return <Loader />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <main className="product-detail">
            <div className="detail-container">
                <Link className="back-button" to="/produkte">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="var(--green)"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" /></svg>
                    <span>Produktübersicht</span>
                </Link>
            </div>
            {/* <h1>Unsere Produkte</h1> */}

            {/* {products.map(product => { */}
                {/* const nutrition = product.Naehrwerte ? nutritions[product.Naehrwerte] : null; */}
                {/* return ( */}
                    
                    <article key={product.id} className={`product ${lightOrDark(product.Farbe)}`}>
                        <div className="product-bg" style={{backgroundColor: `${product.Farbe}`}}></div>
                        <div className="product-container">
                            <div className="product-img">
                                {/* <Link to={`/produkte/${product.Bioprodukt}`} className="reference-link"><span>Gibt's auch in Bio</span></Link> */}
                                {product.Bioprodukt && (
                                    <a href={`/produkte/${product.Bioprodukt}`} className="reference-link"><span>Gibt's auch in Bio</span></a>
                                )}
                                {product.Bild && product.Bild.filename_disk ? (
                                    <img
                                        src={getImageUrl(product.Bild)}
                                        alt={product.Name}
                                        loading="lazy"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = "/images/placeholder.png";
                                        }}
                                    />
                                ) : (
                                    <div className="product-img-placeholder">No Icon</div>
                                )}
                            </div>
                            <div className="product-content">
                                <h2 style={{color: `${getColor(product.Farbe)}`}}>{product.Kategorie?.Name}</h2>
                                <h1 style={{color: `${getColor(product.Farbe)}`}}>{product.Name}</h1>

                                {product.Beschreibung && (
                                    <div className="product-description">
                                        <p>{product.Beschreibung}</p>
                                    </div>
                                )}

                                {product.Zutaten && (
                                    <div className="product-ingredients">
                                        <h3 style={{color: `${getColor(product.Farbe)}`}}>Zutaten:</h3>
                                        <p>{product.Zutaten}</p>
                                    </div>
                                )}

                                {product.Allergeninfo && (
                                    <div className="product-allergens">
                                        {product.Allergeninfo}
                                    </div>
                                )}

                                {/* {product.Anwendung && (
                                    <div className="product-instructions">
                                        <h3>Anwendung:</h3>
                                        <p>{product.Anwendung}</p>
                                    </div>
                                )} */}
                                <ProductIcons
                                    color = {`${getColor(product.Farbe)}`}
                                />
                            </div>
                            
                            {product.Naehrwerte && (
                                <div className="nutrition-container">
                                    
                                    <header className="nutrition-header">
                                        <h3>Durchschnittliche Nährwerte</h3>
                                        <h3>pro 100 g</h3>
                                    </header>
                                    {renderNutrition(product.Naehrwerte)}
                                
                                    {product.Nettogewicht && (
                                        <div className='product-weight'>
                                            <h3>Nettogewicht</h3>
                                            <h3>{product.Nettogewicht} g</h3>
                                        </div>
                                    )}
                                </div>
                            )}
                            {/* {product.Mindesthaltbarkeit && (
                                <div className='product_field'>
                                    <h3>Mindesthaltbarkeit:</h3>
                                    <strong>{formatDate(product.Mindesthaltbarkeit)}</strong>
                                </div>
                            )} */}
                            </div>
                    </article>
                {/* ); */}
            {/* })} */}
        </main>
    );
};

export default ProductDetail;
