// Hero1.js
import React from 'react';
import '../styles/Hero1.css';
import logoOutline from '../assets/Doch_Logo_outline.svg';
import logo2 from '../assets/logo2.png';
import pack from '../assets/Verpackung.png';
import logoPack from '../assets/100Marchfeld RGB.svg';
import { Link } from 'react-scroll';

const Hero1 = () => {
  return (
    <section className="hero" id="willkommen" role="img" aria-labelledby="hero-description">
      <p id="hero-description" hidden>
        Das Bild zeigt verschiedene Gemüsesorten, darunter Karotten, Pastinaken,
        Zwiebeln, Knoblauch, Lauch und Petersilie, die dynamisch dargestellt sind
        und durch eine goldene, pulverartige Wolke fliegen. Der Hintergrund ist
        dunkel, wodurch die Farben des Gemüses und des Pulvers besonders leuchten.
        Rechts unten auf dem Bild ist ein Text mit der Aufschrift „100% aus dem
        Marchfeld“ zu sehen, darunter ein stilisiertes Gemüse-Icon. Links unten ist
        die Verpackung des Produkts „DOCH! flavours Allrounder“ sichtbar. Im oberen
        Bereich des Bildes steht die Headline: „Wenn Gemüse fliegt, kommt DOCH!
        raus.“ Darunter folgt der Text: „Lieblingsspeisen würzen und geschmacklich
        abheben.“
      </p>
      {/* <div className="container">
        <h1 className="title">Wenn Gemüse fliegt, <br /> kommt <img src={logoOutline} alt="DOCH!" /> raus.</h1>
        <h2 className="subtitle">Gesunde Würzkraft <br />aus der Natur</h2>
      </div> */}
      <div className="hero-buttons-container">
        <div className="hero-buttons">
          <Link to="hero2" smooth="true" offset={120} duration={500} className="btn">
            Produkte
          </Link>
          <Link to="hero2_2" smooth="true" duration={1500} className="btn">
            Über uns
          </Link>

          {/* <a className="btn" href="#hero2_2">
            Unsere
            <br />
            <b>Geschichte</b>
            Über uns
          </a> */}

          {/* <a className="btn" href="#hero3_2">
            DOCH!
            <br />
            <b>jetzt kaufen</b>
            Jetzt probieren
          </a> */}
          <Link to="hero3_2" offset={-50} smooth="true" duration={2000} className="btn">
            Rezepte & Tipps
          </Link>

          <Link to="hero3_3" smooth="true" duration={2500} className="btn">
            Jetzt kaufen
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero1;
