import React, { useState, useEffect } from 'react';
import '../styles/Hero3.css';
import { Link } from 'react-router-dom';
import logoOutline from '../assets/Doch_Logo_outline.svg';
import recipe1 from '../assets/recipes/Groestl_mit_Ei.jpg';
import reel1 from '../assets/reels/reel1.mp4';
import reel2 from '../assets/reels/reel2.mp4';
import reel3 from '../assets/reels/reel3.mp4';
import reel4 from '../assets/reels/reel4.mp4';
import reel5 from '../assets/reels/reel5.mp4';
import dochSmall from '../assets/dochSmall.png'
import dochBig from '../assets/dochBig.png'
import dochPair from '../assets/dochPair.png'
import produkteRange from '../assets/Produkt_Range.png';



const Hero3_2 = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Определяем, мобильный ли экран


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Считаем экран мобильным, если ширина ≤ 768px
        };

        window.addEventListener('resize', handleResize); // Добавляем слушатель
        return () => window.removeEventListener('resize', handleResize); // Убираем слушатель
    }, []);


    return (
        <section className="hero3_2" id='section3_2'>
            <div className="container">
                <h2 className="title">Lass dich <img src={logoOutline} alt="DOCH!" /> von uns inspirieren.</h2>
            </div>

            <div className="inspo">
                <div className="inspo-container">
                    <article className="blog-entry reel">
                        <div className="video-container" dangerouslySetInnerHTML={{
                            __html: `
                                <video id="reel1" controls>
                                    <source src="${reel1}" type="video/mp4" />
                                </video>`
                            }}
                        />
                    </article>
                    <article className="blog-entry recipe">
                        <img src={recipe1} alt="title" />
                        <div className="recipe-content">
                            <h3>G'röstl mit Ei</h3>
                            <p>Ein österreichischer Klassiker mit einer extra würzigen Note - schnell gemacht und einfach lecker!</p>
                            <a className="button" href="https://www.instagram.com/p/DGSnU5BxOOC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" target="_blank" title="Weiterlesen auf Instagram">Weiterlesen auf Instagram</a>
                        </div>
                    </article>
                    <article className="blog-entry reel">
                        <div className="video-container" dangerouslySetInnerHTML={{
                            __html: `
                                <video id="reel2" controls>
                                    <source src="${reel3}" type="video/mp4" />
                                </video>`
                            }}
                        />
                    </article>
                    <article className="blog-entry reel">
                        <div className="video-container" dangerouslySetInnerHTML={{
                            __html: `
                                <video id="reel3" controls>
                                    <source src="${reel2}" type="video/mp4" />
                                </video>`
                            }}
                        />
                    </article>
                    <article className="blog-entry reel">
                        <div className="video-container" dangerouslySetInnerHTML={{
                            __html: `
                                <video id="reel4" controls>
                                    <source src="${reel4}" type="video/mp4" />
                                </video>`
                            }}
                        />
                    </article>
                    <article className="blog-entry reel">
                        <div className="video-container" dangerouslySetInnerHTML={{
                            __html: `
                                <video id="reel5" controls>
                                    <source src="${reel5}" type="video/mp4" />
                                </video>`
                            }}
                        />
                    </article>
                </div>

                    {/* <p>Ob klassisch, mediterran oder scharf – <strong>DOCH! bietet für jeden Geschmack die passende Würze</strong>. Unsere handverlesenen Produkte gibt es in <strong>Bio oder in unserer bewährten Qualität</strong>, immer mit <strong>wenig Salz, ohne künstliche Zusätze</strong> und voller natürlicher Aromen.</p>
                    <p>Damit du die perfekte Menge für deine Küche hast, gibt es unsere Würzmischungen und -pulver in <strong>verschiedenen Größen</strong> – von der <strong>praktischen 50g-Packung</strong> für den Alltag bis zur <strong>großen 500g-Packung</strong> für echte Würzliebhaber*innen.</p> */}


                {/* <img className='produkteRange' src={produkteRange} alt="DOCH!" aria-labelledby="dochPair-description" />
                <p id="dochPair-description" hidden>
                    Das Bild zeigt zwei Produktverpackungen des DOCH! allrounders vor einem weißen Hintergrund.
                    Links ist eine kleinere, wiederverschließbare Verpackung aus dunkelgrünem Material abgebildet.
                    Auf ihr steht in orangefarbenen Buchstaben „DOCH! flavours allrounder“, ergänzt durch den Claim
                    „Dein Revoluzzer mit natürlicher Würze“. Darunter ist eine kurze Beschreibung des Produkts aufgedruckt.
                    Rechts daneben steht eine größere Verpackung in Form einer Dose, ebenfalls dunkelgrün mit orangen Akzenten. 
                    Auch hier ist das Logo prominent zu sehen, zusammen mit der Produktbezeichnung „DOCH! flavours allrounder“.
                    Die Darstellung vermittelt klar die zwei Größenvarianten des Produkts: eine kompakte 50g-Verpackung und eine größere 500g-Dose.
                </p> */}

            </div>
            {/* <p className='dochSmall-p'>DOCH! allrounder 50g</p>
            <img className='dochSmall' src={dochSmall} alt="DOCH!" />
            <p className='dochBig-p'>DOCH! allrounder 500g</p>
            <img className='dochBig' src={dochBig} alt="DOCH!" /> */}
            
            <div className="container btn-container">
                <Link to="/rezepte" className="button">Mehr Rezepte & Tipps</Link>
            </div>
            
        </section>
    );
};

export default Hero3_2;
