import React from 'react';
import '../styles/Hero3.css';
import { Link } from 'react-scroll';



const Hero3_1 = () => {
    return (
        <section className="hero3_1" id='section3_1'>
            <div className="container">
                <h2>Unser Versprechen an euch</h2>
                <p>Wir haben uns mit <strong>DOCH!</strong> das Ziel gesetzt, eine <strong>neue Art des Würzens</strong> zu schaffen – natürlich, gesund und unverfälscht.</p>
                <p>Unsere handverlesenen Würzmischungen basieren auf einer <strong>einzigartigen Rezeptur</strong>, die <strong>wenig Salz</strong> enthält und den <strong>echten Geschmack der Zutaten</strong> bewahrt. <strong>Keine künstlichen Zusätze, keine Geschmacksverstärker – nur die pure Kraft aus der Natur</strong>.</p>
                <p>Mit DOCH! nutzen wir die besten Zutaten und stimmen sie mit einer <strong>geheimen Mischung</strong> perfekt ab. Das Ergebnis? Eine <strong>feine, harmonische Würze</strong>, die vielseitig einsetzbar ist und jede Mahlzeit auf natürliche Weise verfeinert.</p>
                <p><strong>DOCH! steht für puren Geschmack, Qualität & gesunde Natürlichkeit – für alle, die bewusster genießen wollen.</strong></p>
            </div>
        </section>
    );
};

export default Hero3_1;
