import React, { useState } from 'react';
import '../styles/NewsletterBanner.css';

function NewsletterBanner() {
    const [isVisible, setIsVisible] = useState(true);
    const [animateOut, setAnimateOut] = useState(false);
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    if (!isVisible) return null;

    const handleClose = () => {
        setAnimateOut(true);
        setTimeout(() => {
            setIsVisible(false);
        }, 500);
    };

    const validateEmail = (email) => {
        if (!email) {
            return 'Bitte geben Sie Ihre E-Mail-Adresse ein.';
        }
        if (!email.includes('@')) {
            return 'Ihre E-Mail-Adresse muss ein "@" enthalten.';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationError = validateEmail(email);
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }
        setErrorMessage('');
        setStatus('sending');
        const date_created = new Date().toISOString();

        try {
            const response = await fetch('https://doch-admin.mobile-bühne.at/items/Newsletter_Anmeldung', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, date_created })
            });

            if (response.ok) {
                setStatus('success');
                console.log("Email submitted successfully:", email, date_created);
                setTimeout(() => {
                    setAnimateOut(true);
                    setTimeout(() => {
                        setIsVisible(false);
                    }, 500);
                }, 3000);
            } else {
                setStatus('error');
                console.error("Fehler beim Senden der E-Mail:", response.statusText);
            }
        } catch (error) {
            setStatus('error');
            console.error("Fehler beim Senden der E-Mail:", error);
        }
    };

    return (
        <div className={`newsletter-banner ${animateOut ? "slide-out" : ""}`}>
            <div className="newsletter-content">
                <button className="close-btn" onClick={handleClose}>
                    &times;
                </button>
                <h4>Würze dein Postfach!</h4>
                <p>Melde dich zum Newsletter an und erhalte geniale Rezepte, spannende Aktionen und Inspirationen aus der DOCH! Geschmacks-Manufaktur.</p>
                <form onSubmit={handleSubmit} noValidate>
                    <input
                        type="email"
                        placeholder="E-Mail"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrorMessage('');
                        }}
                    />
                    {errorMessage && <span className="error-message">{errorMessage}</span>}

                    <button type="submit">Jetzt anmelden!</button>
                </form>
                {status === 'sending' && <h4 style={{ textAlign: 'center', lineHeight: '1', fontWeight: '600' }}>Sende...</h4>}
                {status === 'success' && <h4 style={{ textAlign: 'center', lineHeight: '1', fontWeight: '600' }}>Vielen Dank für Ihre Anmeldung!</h4>}
                {status === 'error' && <h4 style={{ textAlign: 'center', lineHeight: '1', fontWeight: '600' }}>Fehler beim Senden, bitte versuchen Sie es später.</h4>}
            </div>
        </div>
    );
}

export default NewsletterBanner;
