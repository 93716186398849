// App.js
import "./App.css";
import Navbar from "./components/Navbar";
import Hero1 from "./components/Hero1";
import Hero2 from "./components/Hero2";
import Hero2_1 from "./components/Hero2_1";
import Hero2_2 from "./components/Hero2_2";
import Hero3 from "./components/Hero3";
import Hero3_1 from "./components/Hero3_1";
import Hero3_2 from "./components/Hero3_2";
import Hero3_3 from "./components/Hero3_3";
import Footer from "./components/Footer";
import Impressum from "./components/Impressum";
import AGB from "./components/AGB";
import Datenschutz from "./components/Datenschutz";
import Products from "./components/Produkte";
import ProductDetail from "./components/ProdukteDetail";
import Loader from "./components/Loader";
import NewsletterBanner from "./components/NewsletterBanner";
import { Element, scroller, Events, scrollSpy } from "react-scroll";
import { useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

function Home() {
  const sectionNames = [
    "hero1",
    "hero2",
    "hero2_1",
    "hero2_2",
    "hero3",
    "hero3_1",
    "hero3_2",
    "hero3_3",
    "footer",
  ];

  const [currentSection, setCurrentSection] = useState(0);
  const currentSectionRef = useRef(currentSection);
  const totalSections = sectionNames.length;
  const hero2_2Ref = useRef(null);
  const isThrottledRef = useRef(false);
  const scrollAmountRef = useRef(0);
  const isAnimatingRef = useRef(false); // Flag for tracking animation

  const SCROLL_THRESHOLD = 510; // Scroll threshold in pixels

  // Update currentSectionRef when currentSection changes
  /* useEffect(() => {
    currentSectionRef.current = currentSection;
  }, [currentSection]);

  const scrollToSection = (sectionIndex) => {
    const newSection = Math.max(0, Math.min(sectionIndex, totalSections - 1));
    if (newSection !== currentSectionRef.current) {
      isAnimatingRef.current = true; // Set animation flag

      setCurrentSection(newSection);
      scrollAmountRef.current = 0;

      // Animation completion handler
      setTimeout(() => {
        isAnimatingRef.current = false; // Reset flag after animation
      }, 1000); // Delay to complete animation
    }
  };

  useEffect(() => {
    scroller.scrollTo(sectionNames[currentSection], {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }, [currentSection, sectionNames]);

  // Handle scroll end events via react-scroll
  useEffect(() => {
    const handleScrollEnd = (to, element) => {
      const index = sectionNames.indexOf(to);
      if (index !== -1 && index !== currentSectionRef.current) {
        setCurrentSection(index);
      }
    };

    Events.scrollEvent.register("end", handleScrollEnd);

    // Initialize scrollSpy
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("end", handleScrollEnd);
    };
  }, [sectionNames]); */

  return (
    <div className="scroll-container">
        <Hero1 />
        <Hero2 />
        <Hero2_1 />
        <div ref={hero2_2Ref} className="hero2_2-container">
          <Hero2_2 />
        </div>
        <Hero3 />
        <Hero3_1 />
        <Hero3_2 />
        <Hero3_3 />
      {/* <Element name="footer" className="section" id="footer">
        <Footer/>
      </Element> */}
    </div>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/agb" element={<AGB />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/produkte" element={<Products />} />
        <Route path="/produkte/:id" element={<ProductDetail />} />
        <Route path="/loader" element={<Loader />} />
      </Routes>
      {/* {location.pathname !== '/' && <Footer />} */}
      <NewsletterBanner></NewsletterBanner>
      <Footer />
    </>
  );
}

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return <Router>{isLoading ? <Loader /> : <AppContent />}</Router>;
}

export default App;
