// src/components/Hero2.js
import React from 'react';
import '../styles/Hero2.css';
import logo from '../assets/Doch_Logo.svg';
import logoOutline from '../assets/Doch_Logo_outline.svg';
import Banner from '../assets/Doch_Banner_1000x300_5.jpg';
import ProductsBioGemuesewuerze from '../assets/Produktrange_Bio_Gemuesewuerze.png';
import ProductsBioWuerzpulver from '../assets/Produktrange_Bio_Wuerzpulver.png';
import ProductsGemuesewuerze from '../assets/Produktrange_Gemuesewuerze.png';
import ProductsWuerzpulver from '../assets/Produktrange_Wuerzpulver.png';
import bannerProduct from '../assets/Produkt_Bio_Grill_BBQ.png';
import bannerProduct2 from '../assets/Produkt_Grill_BBQ.png';
import { Link } from 'react-router-dom';


const Hero2 = () => {
    return (
        <>
        <section className="hero2 produkte-bio-hero" id="warum-doch">
            <div className="container">
                <h2 className="title">Würz <img src={logoOutline} alt="DOCH!" /> nach deinem Geschmack.</h2>
                <p>Unsere hochwertigen Würzmischungen und -pulver gibt es in zwei Linien: <strong>Bio & Klassik</strong>. Fein vermahlenes Gemüse, harmonisch abgestimmte Kräuter und Gewürze – für puren, natürlichen Geschmack. Wähle deine Würze und verfeinere deine Gerichte ganz nach deinem Stil.</p>
                <div className="product-blocks">
                    <div className="product-block">
                        <div className="product-block-contant">
                            <h3><span>Bio</span> Gemüsewürze</h3>
                            <p>100% kontrolliert biologische Zutaten für puren, unverfälschten Geschmack – ohne Kompromisse. Frei von künstlichen Zusätzen und mit besonders schonender Verarbeitung, damit das Beste der Natur erhalten bleibt.</p>
                        </div>
                        <img src={ProductsBioGemuesewuerze} alt="Bio Gemüsewürze" />
                    </div>
                    <div className="product-block">
                        <div className="product-block-contant">
                            <h3><span>Bio</span> Würzpulver</h3>
                            <p>Fein vermahlenes Bio-Gemüse – 100% natürlich, intensiv und unverfälscht. Perfekt für alle, die bewusst würzen möchten.</p> 
                        </div>
                        <img src={ProductsBioWuerzpulver} alt="Bio Würzpulver" />
                    </div>
                </div>
                <Link to="/produkte" className="button">Produkte entdecken</Link>
                <Link to="/produkte" className="button secondary">Alle Produkte ansehen</Link>
            </div>
        </section>
            <div className="banner">
                {/* <img className="banner-bg" src={Banner} alt="Grillen" /> */}
                <div className="banner-container">
                    <div className="banner-content">
                        <h2 className="title">Grill <img src={logoOutline} alt="DOCH!" /> mal anders.</h2>
                        <p>Weil dein Steak Besseres verdient als nur Salz & Pfeffer.</p>
                        <Link className="button" to="/produkte/11">Mehr erfahren</Link>
                    </div>
                    <div className="banner-product">
                        {/* <img className="product2" src={bannerProduct2} alt="Gemüsegewürz Grill & BBQ" /> */}
                        <img src={bannerProduct} alt="Bio Gemüsegewürz Grill & BBQ" />
                    </div>
                </div>
            </div>
        <section className="hero2 produkte-hero">
            <div className="container">
                <h2 className="title">Oder <img src={logoOutline} alt="DOCH!" /> lieber unsere Klassiker?</h2>
                <p>DOCH! gibt es auch in der klassischen Variante mit der bewährten Rezeptur - wenig Salz, voller Geschmack, 100% Natürlichkeit!</p>
                <div className="product-blocks">
                    <div className="product-block">
                        <div className="product-block-contant">
                            <h3>Gemüsewürze</h3>
                            <p>Natürlich würzen mit fein abgestimmten Zutaten. Unsere Würzmischungen bieten puren Geschmack, harmonisch kombiniert und vielseitig einsetzbar – für Suppen, Saucen, Eintöpfe oder zum Verfeinern.</p>
                        </div>
                        <img src={ProductsGemuesewuerze} alt="Gemüsewürze" />
                    </div>
                    <div className="product-block">
                        <div className="product-block-contant">
                            <h3>Würzpulver</h3>
                            <p>Fein vermahlenes Gemüse in seiner reinsten Form. Unsere Würzpulver bringen natürlichen Geschmack in jedes Gericht.</p> 
                        </div>
                        <img src={ProductsWuerzpulver} alt="Würzpulver" />
                    </div>
                </div>
                <Link to="/produkte" className="button">Produkte entdecken</Link>
                <Link to="/produkte" className="button secondary">Alle Produkte ansehen</Link>
            </div>
        
            {/* <div className="down-button">
                <Link
                    to="section2_1"
                    smooth={true}
                    duration={500}
                    className=""
                >
                    <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                        <path d="M12 16.5l-8-8h16z" />
                    </svg>
                </Link>

            </div>
            <div className="up-button">
                <Link
                    to="hero"
                    smooth={true}
                    duration={500}
                    className="up-button-link"
                // offset={-60} // Отступ для учета фиксированного Navbar (например, 60px)
                >
                    <svg className="arrow-up" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                        <path d="M12 7.5l8 8H4z" />
                    </svg>
                </Link>
            </div> */}
        </section>
        </>
    );
};

export default Hero2;
