// src/components/Hero2_2.js
import React, { useState, useEffect } from 'react';
import '../styles/Hero2.css';
import logoOutline from '../assets/Doch_Logo_outline.svg';
import logo from '../assets/Doch_Logo.svg';
import bg3 from '../assets/Ueber_Uns_BG.png';
import { Link } from 'react-scroll';

const Hero2_2 = () => {
    const [showDownButton, setShowDownButton] = useState(true);

    useEffect(() => {
        const orangeElement = document.getElementById('orange');

        if (!orangeElement) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setShowDownButton(false);
                    } else {
                        setShowDownButton(true);
                    }
                });
            },
            { threshold: 0.5 } // Настройте порог по необходимости
        );

        observer.observe(orangeElement);

        return () => {
            if (orangeElement) {
                observer.unobserve(orangeElement);
            }
        };
    }, []);

    return (
        <section className="hero2_2" id='section2_2'>
            <div className='overflow'>
                <div className="container">
                    {/* {showDownButton && ( */}
                    <div className='content'>
                        <h2 className="title"><img src={logo} alt="DOCH!" /> <strong>– das sind wir,</strong> <br />Christoph und Dominik</h2>
                        <p>
                            Wir teilen die Leidenschaft für gutes, ehrliches Essen und eine tiefe Wertschätzung für nachhaltige Landwirtschaft.
                        </p>
                        {/* <Link className="button" to="/ueber-uns">Mehr über uns</Link> */}
                    </div>
                    {/* )} */}


                </div>

                <img className='bg3Img' src={bg3} alt="Background" aria-labelledby="bg3-description" />
                <p id="bg3-description" hidden>
                    Christoph und Dominik Salomon, die Gründer von DOCH!, 
                    sitzen nebeneinander an einem Tisch und lächeln direkt in die Kamera. 
                    Vor ihnen liegt eine Auswahl an frischem Gemüse, darunter Karotten, 
                    Pastinaken, Lauch, Zwiebeln und Petersilie, zusammen mit 
                    einer Verpackung des DOCH! allrounders. Der Hintergrund ist neutral weiß, 
                    wodurch die Personen und das Produkt im Mittelpunkt stehen.
                </p>
                <div className="quotes">
                    <div className="quotes-container">
                        <div className="quote-cell">
                            <p className="quote">Ich komme aus einer Familie traditioneller Gemüsehändler und bringe als gelernter Koch das Wissen und die Leidenschaft für echte Geschmacksentwicklung mit.</p>
                            <p className="author">— Christoph Salomon</p>
                        </div>
                        <div className="quote-cell">
                            <p className="quote">Früher war meinem Mann mein Essen oft zu scharf, doch dank unseren perfekt abgestimmten Würzmischungen kann ich jetzt wieder nach Lust und Laune kochen – ganz ohne Überwürzen!</p>
                            <p className="author">— Dominik Salomon</p>
                        </div>
                    </div>
                </div>

                {/* Кнопка вниз (DownButton) */}
                {/* {showDownButton && (
                    <>
                        <div className="down-button btn3D">
                            <Link
                                to="photo"
                                smooth={true}
                                duration={500}
                                className="down-button-link"
                                aria-label="Scroll to photo section"
                                role="button"
                            >
                                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M12 16.5l-8-8h16z" />
                                </svg>
                            </Link>
                        </div>


                        <div className="up-button btn3U">
                            <Link
                                to="section2_1"
                                smooth={true}
                                duration={500}
                                className="up-button-link"
                                aria-label="Scroll to section 2_1"
                                role="button"
                            // offset={-60} // Отступ для учета фиксированного Navbar (например, 60px)
                            >
                                <svg className="arrow-up" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M12 7.5l8 8H4z" />
                                </svg>
                            </Link>
                        </div>
                    </>


                )}


                {!showDownButton && (
                    <>
                        <div className="down-button btn4D">
                            <Link
                                to="section3"
                                smooth={true}
                                duration={500}
                                className="down-button-link"
                                aria-label="Scroll to photo section"
                                role="button"
                            >
                                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M12 16.5l-8-8h16z" />
                                </svg>
                            </Link>
                        </div>

                        <div className="up-button btn4U">
                            <Link
                                to="section2_2"
                                smooth={true}
                                duration={500}
                                className="up-button-link"
                                aria-label="Scroll to section 2_1"
                                role="button"
                            // offset={-60} // Отступ для учета фиксированного Navbar (например, 60px)
                            >
                                <svg className="arrow-up" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
                                    <path d="M12 7.5l8 8H4z" />
                                </svg>
                            </Link>
                        </div>
                    </>


                )} */}

            </div>
        </section>
    );
};

export default Hero2_2;
