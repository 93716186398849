import React, { useState, useEffect, useRef } from 'react';
import '../styles/Hero3.css';
import logoOutline from '../assets/Doch_Logo_outline.svg';
import photo1 from '../assets/Ueber_Uns_1.jpg';
import photo2 from '../assets/Ueber_Uns_2.jpg';
import photo3 from '../assets/Ueber_Uns_3.jpg';
import photo4 from '../assets/Ueber_Uns_4.jpg';

const Hero3 = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Определяем, мобильный ли экран
    const scrollRef = useRef(null);

    const handleWheel = (event) => {
        event.preventDefault();
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += event.deltaY;
        }
    };

    // Отслеживаем изменение ширины экрана
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Считаем экран мобильным, если ширина ≤ 768px
        };

        window.addEventListener('resize', handleResize); // Добавляем слушатель
        return () => window.removeEventListener('resize', handleResize); // Убираем слушатель
    }, []);

    return (
        <section className="hero3" id="section3">
            <div className="container">
                <h2 className="title">Unsere Idee zu <img src={logoOutline} alt="DOCH!" /></h2>
                <p>Alles begann in der eigenen Küche – mit der Frage: <strong>Warum gibt es keine Würzmischung, die wirklich gesund ist? Ohne Zusätze, ohne Kompromisse?</strong> Als leidenschaftliche Köche suchten wir nach einer Möglichkeit, Gerichte nicht nur lecker, sondern auch natürlich und bewusst zu würzen.</p>
                <p>Die Inspiration für die <strong>DOCH! Geschmacks-Manufaktur</strong> kam schließlich bei einer <strong>Fahrradtour durchs Marchfeld</strong>. Umgeben von der Vielfalt und Frische der regionalen Gemüsesorten erkannten wir das Potenzial, hochwertige Zutaten in einer neuen, haltbaren Form in die Küchen der Stadt zu bringen.</p>
                <p>Nach unzähligen Experimenten entstand <strong>eine Linie natürlicher Würzmischungen und -pulver</strong>, die <strong>puren Geschmack mit nachhaltigem Kochen</strong> verbindet. Jede Prise DOCH! steht für bewusstes, gesundes und unkompliziertes Würzen.</p>
                <p>Mit der <strong>DOCH! Geschmacks-Manufaktur</strong> wollen wir nicht nur den Geschmack revolutionieren, sondern auch zeigen, dass <strong>natürliches Würzen einfach sein kann – ohne auf Genuss zu verzichten</strong>.</p>
            </div>

            <div className="gallery-container">
                <div className="gallery">
                    <img  src={photo1} alt="Beschreibung Foto 1" />
                    <img  src={photo2} alt="Beschreibung Foto 2" />
                    <img  src={photo3} alt="Beschreibung Foto 3" />
                    <img  src={photo4} alt="Beschreibung Foto 4" />
                </div>
            </div>
        </section>
    );
};

export default Hero3;
