// Navbar.js
import React, { useState } from 'react';
import '../styles/Navbar.css';
import logo from '../assets/Doch_Logo.svg';
import logoOutline from '../assets/Doch_Logo_outline.svg';
import { Link as ScrollLink } from 'react-scroll';
import { Link, useNavigate, useLocation } from 'react-router-dom';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const logoSvg = () => {
    return (
      <svg id="logo-nav" data-name="logo-nav" fill="#f29449" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.11 145.2">
        <g>
          <path d="M54.52,102.32h-8.73l.03-59.45h8.7c4.66,0,5.57,1.32,5.57,29.73s-.91,29.73-5.57,29.73M54.52,32.06h-23.24v81.07h23.24c18.64,0,21.94-2.9,21.94-40.53s-3.3-40.53-21.94-40.53"/>
          <path d="M109.36,103.01c-4.66,0-6.88-1.43-6.88-30.41s2.22-30.41,6.88-30.41,6.99,1.42,6.99,30.41-2.22,30.41-6.99,30.41M109.36,31.38c-18.53,0-23.24,3.09-23.24,41.22s4.72,41.22,23.24,41.22,23.36-3.1,23.36-41.22-4.72-41.22-23.36-41.22"/>
          <path d="M166.76,42.18c3.98,0,6.65,3.73,6.88,22.9h13.87c1.36,0,2.5-1.18,2.39-2.75-1.02-25.94-6.99-30.95-23.13-30.95-18.53,0-23.24,3.1-23.24,41.22s4.72,41.22,23.24,41.22c16.25,0,22.22-1.42,23.13-28.25.11-1.57-1.02-2.75-2.39-2.75h-13.87c-.23,20.05-2.78,20.19-6.88,20.19-4.66,0-6.88-1.43-6.88-30.41s2.22-30.41,6.88-30.41"/>
          <path d="M241.72,32.06h-13.07v39.16h-11.54v-39.16h-15.46v81.07h15.46v-29.14h11.54v29.14h15.46V34.52c0-1.38-1.14-2.46-2.39-2.46"/>
          <path d="M269.45,32.06h-12.05v57.14c0,1.38,1.02,1.23,2.27,1.23h12.16v-55.91c0-1.38-1.14-2.46-2.39-2.46"/>
          <path d="M269.45,100.65h-9.66c-1.36,0-2.39.98-2.39,2.16v8.25c0,1.18,1.02,2.06,2.39,2.06h9.66c1.36,0,2.39-.88,2.39-2.06v-8.35c0-1.18-1.02-2.06-2.39-2.06"/>
        </g>
      </svg>
    )
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
        <div  className="navbar">
          {location.pathname === '/' ? (
            <ScrollLink to="willkommen" smooth="true" duration={1000} className={`navbar-logo ${isOpen ? 'hidden' : ''}`}>
              {<img src={logo} alt="logo" />}
            </ScrollLink>
           ) : (
            <Link to="/" className={`navbar-logo ${isOpen ? 'hidden' : ''}`}>
              <img src={logo} alt="logo" />
            </Link>
          )}

        

        <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
          
          {location.pathname === '/' ? (
            <ScrollLink to="willkommen" smooth="true" duration={1000} onClick={() => setIsOpen(false)} className="navLink first">
              <svg id="logo-nav" data-name="logo-nav" fill="#f29449" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.11 145.2">
                <g>
                  <path d="M54.52,102.32h-8.73l.03-59.45h8.7c4.66,0,5.57,1.32,5.57,29.73s-.91,29.73-5.57,29.73M54.52,32.06h-23.24v81.07h23.24c18.64,0,21.94-2.9,21.94-40.53s-3.3-40.53-21.94-40.53"/>
                  <path d="M109.36,103.01c-4.66,0-6.88-1.43-6.88-30.41s2.22-30.41,6.88-30.41,6.99,1.42,6.99,30.41-2.22,30.41-6.99,30.41M109.36,31.38c-18.53,0-23.24,3.09-23.24,41.22s4.72,41.22,23.24,41.22,23.36-3.1,23.36-41.22-4.72-41.22-23.36-41.22"/>
                  <path d="M166.76,42.18c3.98,0,6.65,3.73,6.88,22.9h13.87c1.36,0,2.5-1.18,2.39-2.75-1.02-25.94-6.99-30.95-23.13-30.95-18.53,0-23.24,3.1-23.24,41.22s4.72,41.22,23.24,41.22c16.25,0,22.22-1.42,23.13-28.25.11-1.57-1.02-2.75-2.39-2.75h-13.87c-.23,20.05-2.78,20.19-6.88,20.19-4.66,0-6.88-1.43-6.88-30.41s2.22-30.41,6.88-30.41"/>
                  <path d="M241.72,32.06h-13.07v39.16h-11.54v-39.16h-15.46v81.07h15.46v-29.14h11.54v29.14h15.46V34.52c0-1.38-1.14-2.46-2.39-2.46"/>
                  <path d="M269.45,32.06h-12.05v57.14c0,1.38,1.02,1.23,2.27,1.23h12.16v-55.91c0-1.38-1.14-2.46-2.39-2.46"/>
                  <path d="M269.45,100.65h-9.66c-1.36,0-2.39.98-2.39,2.16v8.25c0,1.18,1.02,2.06,2.39,2.06h9.66c1.36,0,2.39-.88,2.39-2.06v-8.35c0-1.18-1.02-2.06-2.39-2.06"/>
                </g>
              </svg>
            </ScrollLink>
          ) : (
            <Link to="/" className="navLink first" onClick={() => setIsOpen(false)}>
              <svg id="logo-nav" data-name="logo-nav" fill="#f29449" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.11 145.2">
                <g>
                  <path d="M54.52,102.32h-8.73l.03-59.45h8.7c4.66,0,5.57,1.32,5.57,29.73s-.91,29.73-5.57,29.73M54.52,32.06h-23.24v81.07h23.24c18.64,0,21.94-2.9,21.94-40.53s-3.3-40.53-21.94-40.53"/>
                  <path d="M109.36,103.01c-4.66,0-6.88-1.43-6.88-30.41s2.22-30.41,6.88-30.41,6.99,1.42,6.99,30.41-2.22,30.41-6.99,30.41M109.36,31.38c-18.53,0-23.24,3.09-23.24,41.22s4.72,41.22,23.24,41.22,23.36-3.1,23.36-41.22-4.72-41.22-23.36-41.22"/>
                  <path d="M166.76,42.18c3.98,0,6.65,3.73,6.88,22.9h13.87c1.36,0,2.5-1.18,2.39-2.75-1.02-25.94-6.99-30.95-23.13-30.95-18.53,0-23.24,3.1-23.24,41.22s4.72,41.22,23.24,41.22c16.25,0,22.22-1.42,23.13-28.25.11-1.57-1.02-2.75-2.39-2.75h-13.87c-.23,20.05-2.78,20.19-6.88,20.19-4.66,0-6.88-1.43-6.88-30.41s2.22-30.41,6.88-30.41"/>
                  <path d="M241.72,32.06h-13.07v39.16h-11.54v-39.16h-15.46v81.07h15.46v-29.14h11.54v29.14h15.46V34.52c0-1.38-1.14-2.46-2.39-2.46"/>
                  <path d="M269.45,32.06h-12.05v57.14c0,1.38,1.02,1.23,2.27,1.23h12.16v-55.91c0-1.38-1.14-2.46-2.39-2.46"/>
                  <path d="M269.45,100.65h-9.66c-1.36,0-2.39.98-2.39,2.16v8.25c0,1.18,1.02,2.06,2.39,2.06h9.66c1.36,0,2.39-.88,2.39-2.06v-8.35c0-1.18-1.02-2.06-2.39-2.06"/>
                </g>
              </svg>
            </Link>
          )}

          <Link to="/produkte" className="navLink" onClick={() => setIsOpen(false)}>
            Produkte
          </Link>

          {/* <Link to="/rezepte" className="navLink" onClick={() => setIsOpen(false)}>
            Rezepte & Tipps
          </Link> */}

          <Link to="/agb" className="navLink" onClick={() => setIsOpen(false)}>
            AGB
          </Link>

          <Link to="/datenschutz" className="navLink" onClick={() => setIsOpen(false)}>
            Datenschutz
          </Link>

          <Link to="/impressum" className="navLink" onClick={() => setIsOpen(false)}>
            Impressum
          </Link>
        </div>
        {/* <div className="nav-container"> */}
          {/* <Link to="/produkte" className="produkt-link">Produkte</Link> */}
          <button className="nav-button" onClick={toggleMenu}>
            <div className={`burger-menu ${isOpen ? 'open' : ''}`}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </button>
          
        {/* </div> */}
        
      </div>
    </nav>
  );
};

export default Navbar;
